<template>
  <b-row class="symptom-card">
    <b-col cols="2">
      <component :is="getSymptomIcon" />
    </b-col>
    <b-col cols="9 ml-3">
      <p>{{ symptom.name }} {{ getSymptomIntensity }}</p>
      <span>
        {{ moment(symptom.datetime).format('HH:mm') }} -
        {{ getSymptomBodyPart(symptom.body_part) }}
      </span>
    </b-col>
  </b-row>
</template>

<script>
import {
  getSymptomBodyPart,
  SYMPTOMS_IMAGES,
  SYMPTOM_INTENSITY
} from '@/utils/symptomsUtils'

export default {
  name: 'SymptomCard',
  props: {
    symptom: {
      type: Object,
      required: true
    }
  },
  computed: {
    getSymptomIntensity() {
      return SYMPTOM_INTENSITY[this.symptom.intensity]
    },
    getSymptomIcon() {
      return SYMPTOMS_IMAGES[this.symptom.name]
    }
  },
  methods: {
    getSymptomBodyPart
  }
}
</script>

<style lang="scss" scoped>
.symptom-card {
  padding: 10px;
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  background-color: var(--neutral-000);
  max-width: 95%;
  margin-bottom: 2%;

  p,
  span {
    font-size: min(1.2vw, 16px);
    font-weight: 700;
    color: var(--type-active);
  }

  span {
    font-weight: 400;
  }
}
</style>
