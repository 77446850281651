<template>
  <b-col cols="3">
    <vc-calendar
      :attributes="attributes"
      @dayclick="value => this.$emit('dayclick', value)"
      :max-date="new Date()"
      :from-page.sync="myFromPage"
      @update:page="pageChange"
      @update:from-page="pageChange"
    />

    <hr />

    <div class="legend d-flex flex-column">
      <b-col
        cols="12"
        class="mb-2"
        v-for="(legend, index) in legends"
        :key="index"
      >
        <small :class="`circle ${legend.color}`" />
        <small class="text">{{ legend.text }}</small>
      </b-col>
    </div>
  </b-col>
</template>

<script>
export default {
  name: 'TreatmentCalendar',
  props: {
    attributes: {
      type: Array,
      required: true
    },
    legends: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    myFromPage: null
  }),
  methods: {
    pageChange(page) {
      this.$emit('pageChange', page)
    }
  }
}
</script>

<style lang="scss" scoped>
.vc-container {
  border: none;
}
.legend {
  margin-top: 25% !important;
  .circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;

    .text {
      font-size: min(1vw, 12px);
      font-weight: 600;
      color: var(--type-active);
    }

    &.blue {
      background-color: var(--blue-500);
    }

    &.neutral {
      background-color: var(--neutral-200);
    }

    &.orange {
      background-color: var(--orange);
    }
  }
}
</style>
