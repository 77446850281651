import { getPluralSingularTextSeparated } from './stringUtils';

import ArdenciaIcon from '@/assets/icons/glauco/ardencia.svg';
import CoceiraIcon from '@/assets/icons/glauco/coceira.svg';
import DorIcon from '@/assets/icons/glauco/dor.svg';
import VisaoEmbacadaIcon from '@/assets/icons/glauco/embacada.svg';
import InchacoIcon from '@/assets/icons/glauco/inchaco.svg';
import PioraDaVisaoIcon from '@/assets/icons/glauco/piora.svg';
import SangramentoIcon from '@/assets/icons/glauco/sangramento.svg';
import SecrecaoIcon from '@/assets/icons/glauco/secrecao.svg';
import SensibilidadeIcon from '@/assets/icons/glauco/sensibilidade.svg';
import VermelhidaoIcon from '@/assets/icons/glauco/vermelhidao.svg';

export const SYMPTOM_INTENSITY = {
  1: 'pouca',
  2: 'moderada',
  3: 'média',
  4: 'muita',
  5: 'intensa',
};

export const SYMPTOM_BODY_PART = {
  right_eye: 'No olho direito',
  left_eye: 'No olho esquerdo',
  both_eyes: 'Ambos os olhos',
  head: 'Na cabeça',
};

export const SYMPTOMS_IMAGES = {
  'Ardência': ArdenciaIcon,
  Dor: DorIcon,
  'Vermelhidão': VermelhidaoIcon,
  'Visão embaçada': VisaoEmbacadaIcon,
  Coceira: CoceiraIcon,
  'Inchaço': InchacoIcon,
  'Piora da visão': PioraDaVisaoIcon,
  'Sensibilidade a luz': SensibilidadeIcon,
  'Secreção': SecrecaoIcon,
  Sangramento: SangramentoIcon,
};

export const getSymptomBodyPart = bodyPart => {
  let groupedArray = [];

  const hasBothEyes =
    bodyPart.includes('right_eye') && bodyPart.includes('left_eye');

  if (hasBothEyes) {
    groupedArray.push(SYMPTOM_BODY_PART.both_eyes);

    const filteredBodyPart = bodyPart.filter(part => {
      if (part !== 'left_eye' && part !== 'right_eye') {
        return part;
      }
    });
    groupedArray = [...groupedArray, ...filteredBodyPart];
  } else {
    groupedArray = bodyPart;
  }

  const finalBodyPartArray = groupedArray.map(bodyPart => {
    if (SYMPTOM_BODY_PART[bodyPart]) {
      return SYMPTOM_BODY_PART[bodyPart];
    }
    return bodyPart;
  });
  return getPluralSingularTextSeparated(finalBodyPartArray);
};
