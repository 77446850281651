<template>
  <div id="symptoms">
    <CardHeader
      title="Sintomas"
      :showPeriod="false"
      :options="options"
      @changeOption="changeOption"
      class="mb-3"
    />
    <b-row no-gutters class="symptoms-container">
      <v-treatment-calendar
        :attributes="calendarAttributes"
        @dayclick="changeCalendarDay"
        @pageChange="changeCalendarPage"
        :legends="legends"
      />

      <b-col lg="8" sm="12" class="ml-auto">
        <perfect-scrollbar ref="scroll">
          <b-row
            cols="2"
            class="card-container"
            no-gutters
            v-if="filteredSymptoms.length"
          >
            <b-col cols="6" class="col-symptom">
              <v-symptom-card
                v-for="symptom in filteredSymptoms"
                :key="symptom.id"
                :symptom="symptom"
                class="ml-2"
              />
            </b-col>
          </b-row>
          <b-row v-else class="justify-content-center">
            <no-filters-results :message="noFilterMessage" />
          </b-row>
        </perfect-scrollbar>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CardHeader from '@/layouts/InvoicingTiss/Dashboard/CardHeader'
import TreatmentCalendar from '@/components/Glauco/Dashboard/Patient/TreatmentCalendar'
import SymptomCard from '@/components/Glauco/Dashboard/Patient/SymptomCard'
import NoFiltersResults from '@/components/General/noFilterResults'

export default {
  name: 'Symptoms',
  data() {
    return {
      symptomsInMonth: [],
      symptomsInDay: [],
      patientId: this.$route.params.patientId,
      date: this.moment().format('YYYY-MM-DD'),
      options: [
        { label: 'Todos os sintomas', value: null },
        { label: 'Ardência', value: 'Ardência' },
        { label: 'Dor', value: 'Dor' },
        { label: 'Vermelhidão', value: 'Vermelhidão' },
        { label: 'Coceira', value: 'Coceira' },
        { label: 'Inchaço', value: 'Inchaço' },
        { label: 'Piora da visão', value: 'Piora da visão' },
        { label: 'Sensibilidade', value: 'Sensibilidade' },
        { label: 'Secreção', value: 'Secreção' },
        { label: 'Sangramento', value: 'Sangramento' }
      ],
      option: null,
      legends: [
        {
          color: 'blue',
          text: 'Sintoma registrado'
        },
        {
          color: 'neutral',
          text: 'Nenhum sintoma registrado'
        }
      ]
    }
  },
  components: {
    CardHeader,
    'v-treatment-calendar': TreatmentCalendar,
    'v-symptom-card': SymptomCard,
    'no-filters-results': NoFiltersResults
  },
  computed: {
    calendarAttributes() {
      const arrayDates = this.symptomsInMonth.map(symptom => {
        return new Date(this.moment(symptom.datetime).subtract(3, 'h'))
      })
      return this.symptomsInMonth.map(symptom => {
        return {
          key: symptom.id,
          highlight: true,
          dates: [...new Set(arrayDates)],
          order: 0
        }
      })
    },
    filteredSymptoms() {
      if (!this.option) return this.symptomsInDay
      return this.symptomsInDay.filter(symptom => {
        return symptom.name === this.option
      })
    },
    noFilterMessage() {
      if (this.option)
        return `Nenhum sintoma de ${this.option.toLowerCase()} registrado`
      return 'Nenhum sintoma registrado'
    }
  },
  async created() {
    await this.getPatientSymptomsInMonth()
    await this.getPatientSymptomsInDay()
  },
  methods: {
    async changeCalendarDay(date) {
      this.date = date.id
      await this.getPatientSymptomsInDay()
    },
    async changeCalendarPage(date) {
      let newDate = new Date(date.year, date.month - 1, 1)
      newDate = this.moment(newDate).format('YYYY-MM-DD')
      if (
        this.moment(newDate).format('MM') !==
        this.moment(this.date).format('MM')
      ) {
        await this.getPatientSymptomsInMonth(newDate)
        this.date = newDate
        await this.getPatientSymptomsInDay()
      }
    },
    async getPatientSymptomsInMonth(date = this.date) {
      try {
        const { data } = await this.api.getPatientSymptoms(
          this.patientId,
          date,
          'month'
        )
        this.symptomsInMonth = data.map(symptom => {
          return {
            ...symptom,
            datetime: this.moment(symptom.datetime)
              .subtract(3, 'h')
              .format('YYYY-MM-DD HH:mm:ss')
          }
        })
      } catch (error) {
        this.$toast.error('Erro ao carregar sintomas')
      }
    },
    async getPatientSymptomsInDay() {
      try {
        const { data } = await this.api.getPatientSymptoms(
          this.patientId,
          this.date,
          'day'
        )
        this.symptomsInDay = data[0]?.symptoms ?? []
      } catch (error) {
        this.$toast.error('Erro ao carregar sintomas')
      }
    },
    changeOption(option) {
      this.option = option.value
    }
  }
}
</script>

<style lang="scss">
#symptoms {
  padding: 30px 24px;
  margin: 0 auto;
  background-color: var(--neutral-000);
  border-radius: 8px;
  margin-top: 2%;
  margin-bottom: 2%;

  .title {
    font-weight: 700;
    font-family: 'Red Hat Display';
    font-size: min(1.2vw, 16px);
  }

  .symptoms-container {
    padding: 15px;
    border: 1px solid var(--neutral-200);
    border-radius: 8px;

    .card-container {
      max-height: 420px;

      .col-symptom {
        &:nth-child(even) {
          margin-left: -5px !important;
        }
      }
    }
  }
}
</style>
